import React from 'react'

const Tittle = () => {
  return (
    <div className="bg-home h-90vh bg-center bg-cover flex justify-center items-center">
      <div className="text-7xl max-sm:text-4xl font-bold text-center text-white">
        高松キャンパス生徒向け
        <br />
        ウェブアプリケーション
      </div>
    </div>
  )
}

export default Tittle